<template>
  <div class="bg-white box-shadow border-r px-20 pb-20">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ $t("account_fiat1") }}</h4>
    </div>

    <div class="add_bank">
      <div class="p-20 border-bottom">
        <el-form
          ref="ruleForm"
          class="input-form mx-auto"
          :model="formData"
          :rules="rules"
        >
          <!-- 選擇幣種 -->
          <div class="chooseBin">
            <!-- <div
              class="chooseBin-item"
              :class="coinIndex == 0 ? 'active' : ''"
              @click="coinIndex = 0"
            >
              TWD
            </div> -->
            <div
              class="chooseBin-item"
              :class="coinIndex == 1 ? 'active' : ''"
              @click="coinIndex = 1"
            >
              USD
            </div>
          </div>
          <!-- <el-form-item prop="coin">
            <el-select
              v-model="select1"
              :placeholder='$t("account_bank.placeholder0")'
              clearable
              filterable
              class="th-select"
              style="height:40px"
              size="medium"
              @change="selectFn1"
            >
              <el-option label="TWD" :value="0">
              </el-option>
              <el-option label="USD" :value="1">
              </el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item prop="name">
            <el-input
              v-model="formData.name"
              :placeholder="'*' + $t('account_bank.placeholder1')"
            />
          </el-form-item>
          <el-form-item prop="bank_name">
            <el-input
              v-model="formData.bank_name"
              :placeholder="'*' + $t('account_bank.placeholder2')"
            />
          </el-form-item>
          <el-form-item prop="bank_branch">
            <el-input
              v-model="formData.bank_branch"
              :placeholder="'*' + $t('account_bank.placeholder3')"
            />
          </el-form-item>
          <el-form-item prop="account">
            <el-input
              v-model="formData.account"
              type="number"
              :placeholder="'*' + $t('account_bank.placeholder4')"
            />
          </el-form-item>

          <!-- twd -->
          <el-form-item prop="code" v-if="coinIndex == 0">
            <el-input
              v-model="formData.code"
              :placeholder="$t('account_bank.placeholder5')"
            />
          </el-form-item>

          <!-- usd -->
          <el-form-item prop="address" v-if="coinIndex == 1">
            <el-input
              v-model="formData.address"
              :placeholder="'*' + $t('required_address')"
            />
          </el-form-item>
          <el-form-item prop="swift_code" v-if="coinIndex == 1">
            <el-input
              v-model="formData.swift_code"
              :placeholder="'*' + $t('required_swift')"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-s-promotion"
              class="w-100"
              native-type="button"
              :loading="loading"
              @click="submitForm"
              >{{ $t("account_bank.submitForm") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 銀行卡 -->
    <div v-loading="loadingRecord">
      <div class="d-flex-center px-15 border-bottom">
        <h4 class="mr-auto color-333">{{ $t("account_bank.list_title") }}</h4>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-refresh"
          plain
          @click="fatchRecord()"
          >{{ $t("base.refresh") }}</el-button
        >
      </div>

      <div>
        <el-row
          :gutter="20"
          type="flex"
          justify="center"
          align="middle"
          class="color-gray px-20 py-15 border-bottom font-14"
        >
          <el-col :span="4">{{ $t("account_fiat.col3") }}</el-col>
          <el-col :span="4">{{ $t("account_bank.list_span1") }}</el-col>
          <el-col :span="4">{{ $t("account_bank.list_span2") }}</el-col>
          <el-col :span="4">{{ $t("account_bank.list_span3") }}</el-col>
          <el-col :span="4">{{ $t("account_bank.list_span4") }}</el-col>
          <el-col :span="4">{{ $t("account_bank.list_span5") }}</el-col>
          <!-- USD格式 -->
          <el-col :span="4">{{ $t("required_address0") }}</el-col>
          <el-col :span="4">{{ 'swift code' }}</el-col>
          <el-col :span="4" style="text-align: right">{{
            $t("account_bank.list_span6")
          }}</el-col>
        </el-row>
        <el-row
          v-for="(item, index) in list"
          :key="index"
          :gutter="20"
          type="flex"
          justify="center"
          align="middle"
          class="px-20 py-15 border-bottom font-14"
        >
          <el-col :span="4">{{ item.coin }}</el-col>
          <el-col :span="4">{{ item.name }}</el-col>
          <el-col :span="4">{{ item.bank_name }}</el-col>
          <el-col :span="4">{{ item.bank_branch }}</el-col>
          <el-col :span="4">{{ item.account }}</el-col>
          <el-col :span="4">{{ item.code }}</el-col>

          <el-col :span="4">{{ item.address }}</el-col>
          <el-col :span="4">{{ item.swift_code }}</el-col>

          <el-col :span="4" style="text-align: right">
            <el-button type="danger" size="small" @click="onDel(item.id)">{{
              $t("account_bank.onDel")
            }}</el-button>
          </el-col>
        </el-row>
        <!-- <div class="d-flex-center pt-20">
          <el-pagination
            :hide-on-single-page="true"
            background
            layout="prev, pager, next"
            :page-size="per_page"
            :current-page="current_page"
            :total="list.total"
            @current-change="onPage"
          />
        </div> -->
      </div>
    </div>
    <!-- 銀行卡 -end -->
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import { float1 as regexpFloat } from "@/services/regexp";
import XEClipboard from "xe-clipboard";
export default {
  data() {
    return {
      coinLogo,
      //   info: {
      //     open: false,
      //     auto: false,
      //     address: [
      //       {
      //         tag: "DEFAULT",
      //         address: "",
      //       },
      //     ],
      //   },
      info: {
        address: [
          {
            tag: "erc20",
            address: "",
          },
          {
            tag: "omni",
            address: "",
          },
          {
            tag: "trc20",
            address: "",
          },
        ],
      },
      type: 0,
      tips: this.$t("recharge.tips"),
      loadingData: true,
      loadingRecord: false,
      loading: false,
      formData: {},
      query: { ...this.$route.query },
      // list: {
      //   per_page: 1,
      //   current_page: 1,
      //   total: 0,
      //   data: [],
      // },
      list:[],
      state: [
        ["primary", "Checking"],
        ["success", "Success"],
        ["danger", "Fail"],
        ["info", "Cancel"],
      ],
      timer: null,
      rules: {
        name: [
          {
            required: true,
            message: this.$t("account_bank.message1"),
            trigger: "blur",
          },
        ],
        bank_name: [
          {
            required: true,
            message: this.$t("account_bank.message2"),
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: this.$t("account_bank.message3"),
            trigger: "blur",
          },
        ],
        bank_branch: [
          {
            required: true,
            message: this.$t("account_bank.placeholder3"),
            trigger: "blur",
          },
        ],

        //USD
        address: [
          {
            required: true,
            message: this.$t("required_address"),
            trigger: "blur",
          },
        ],
        swift_code: [
          {
            required: true,
            message: this.$t("required_swift"),
            trigger: "blur",
          },
        ],

        // amount: [
        //   {
        //     required: true,
        //     pattern: regexpFloat,
        //     // message: this.$t("wallet.pea"),
        //     message: this.$t("recharge_new.amount_mes"),
        //     trigger: "blur",
        //   },
        // ],
        // voucher: [
        //   {
        //     required: true,
        //     type: "url",
        //     min: 6,
        //     message: "Please upload a voucher file",
        //     trigger: "blur",
        //   },
        // ],
      },

      // 新增
      verified: "",
      verifyState: [
        ["info", this.$t("account.verify_state.unsubmitted")],
        ["danger", this.$t("account.verify_state.fail")],
        ["primary", this.$t("account.verify_state.checking")],
        ["success", this.$t("account.verify_state.success")],
      ],
      lazy: true,
      srcList: [],
      list_img: [],
      current_page: 1,
      per_page: 5,

      // 幣種選擇
      // coinIndex: 0,
      coinIndex: 1,//2023.2.15
    };
  },
  watch: {},
  created: function () {
    // this.fatchRecord()

    this.fatchRecord();
  },
  methods: {
    //獲取實名認證信息
    // getVerify: async function () {
    //   const { data } = await this.$request.post("v1/user/info");
    //   this.$store.commit("userEmail", data.email);
    //   this.$store.commit("userId", data.id);
    //   this.$store.commit("userVerified", data.verified);
    //   this.verified = data.verified;
    // },

    //選擇幣種
    selectFn1(e) {
      console.log(e);
    },

    //復製地址
    onCopyAddress: function () {
      if (XEClipboard.copy(this.info.address[this.type].address)) {
        this.$message({
          message: this.$t("copied"),
          type: "success",
        });
      }
    },

    submitForm: async function () {
      let _this = this;
      try {
        await this.$refs.ruleForm.validate();
        if (this.list && this.list.length >= 10) {
          return this.$message({
            message: _this.$t("account_bank.err_message1"),
            type: "error",
          });
        }
        this.loading = true;

        let that = this;

        let dataJson = {};
        dataJson.name = this.formData.name;
        dataJson.bank_name = this.formData.bank_name;
        dataJson.bank_branch = this.formData.bank_branch;
        dataJson.account = this.formData.account;
        if (this.coinIndex == 0) {
          dataJson.code = this.formData.code;
          dataJson.coin = 'TWD';
        } else {
          dataJson.address = this.formData.address;
          dataJson.swift_code = this.formData.swift_code;
          dataJson.coin = 'USD';
        }
        
        let $data = await this.$request.post("v1/bank/add", dataJson);
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.formData = {};
            that.fatchRecord();
            that.loading = false;
          },
        });
      } catch (error) {
        this.loading = false;
      }
      //   try {
      //     await this.$refs.ruleForm.validate();
      //     if(!this.formData.voucher){
      //        return this.$message({
      //             message: '請上傳憑證圖片',
      //             type: "error",
      //         });
      //     }
      //     this.loading = true;
      //     await this.$request.post("recharge", this.formData);
      //     this.formData.amount = null;
      //     this.formData.voucher = null;
      //     this.fatchRecord();
      //     this.loading = false;
      //   } catch (error) {
      //     this.loading = false;
      //   }
    },

    //充幣記錄
    fatchRecord: async function () {
      this.loadingRecord = true;
      try {
        const { data } = await this.$request.post("v1/bank/getList");
        this.list = data;
        this.loadingRecord = false;
      } catch (error) {
        this.loadingRecord = false;
      }
    },

    onDel: function (index) {
      this.$confirm(
        this.$t("account_bank.onDel_h"),
        this.$t("account_bank.onDel_tips"),
        {
          confirmButtonText: this.$t("account_bank.confirmButtonText"),
          cancelButtonText: this.$t("account_bank.cancelButtonText"),
          type: "warning",
        }
      ).then(async () => {
        await this.$request.post("v1/bank/del", {
          id: index,
        });
        this.fatchRecord();
      });
    },

    // onPage(page) {
    //   this.current_page = page;
    //   this.fatchRecord();
    // },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  width: 400px;
}
.voucher-img {
  width: 360px;
  height: 270px;
}
.no-authority {
  padding: 120px 0;
  box-sizing: border-box;
  text-align: center;
}
// .th-select{
//   width: 100%;
//   height: 40px;
// }
// .el-input--medium .el-input__inner{
//     height: 40px!important;
// }

.chooseBin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.chooseBin-item {
  font-size: 14px;
  line-height: 40px;
  width: 80px;
  text-align: center;
  border: 1px solid #f9a825;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
}
.chooseBin-item.active {
  background: #f9a825;
  color: #fff;
}
</style>